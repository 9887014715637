<template>
  <div>
    <div v-show="showplayer" title="Video Player">
      <div class="row">
        <div id="prevtrack" v-on:click="prevtrack()">
          <span class="col-xs-2 col-md-1 glyphicon glyphicon-step-backward" aria-hidden="true"></span>
          <img  class="col-xs-3 col-md-1" :src="previmage" />
        </div>
        <ytframe autofocus="1" class="col-xs-12 col-md-8" v-show="showplayer"
          ref="thePlayer" :videoid="mediaid()"
          :autoplay="1"  @ended="onEnded"
          @paused="onPaused" @played="onPlayed" @ready="onReady"
          />
        <div id="nexttrack" v-on:click="nexttrack()">
          <img class="col-xs-offset-7 col-xs-3 col-md-1" :src="nextimage"/>
          <span class="col-xs-2 col-md-1 nexttrack glyphicon glyphicon-step-forward" aria-hidden="true"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      current_track: -1,
      enabled: true
    }
  },
  mounted: function () {
    lyricsindia.playerapp = this;
  },
  computed: {
    previmage: function() {
      var idx;
      if (this.current_track == -1) {
        return '';
      }
      if (this.current_track <= 0) {
        idx = this.playlist.length - 1;
      } else {
        idx = this.current_track - 1;
      }
      return this.playlist[idx].image;
    },
    nextimage: function() {
      var idx;
      if (this.current_track == -1) {
        return '';
      }
      if (this.current_track >= this.playlist.length - 1) {
        idx = 0;
      } else {
        idx = this.current_track + 1;
      }
      return this.playlist[idx].image;
    },
    showplayer: function() {
      return (this.current_track != -1);
    },
  },
  props: {
    playlist: {
      type: Array,
      default: function(){ return [];}
    }
  },
  methods: {
    mediaid: function() {
      if (this.current_track != -1){
        return this.playlist[this.current_track].mediaid;
      }
      else
        return '';
    },
    prevtrack: function() {
      var idx;
      if (this.current_track < 0) {
        idx = 0;
      }
      else if (this.current_track == 0) {
        idx = this.playlist.length - 1;
      } else {
        idx = this.current_track - 1;
      }
      // Start playback of this.current_track
      // console.log("Starting playback of track: " + idx);
      this.playTrack(idx);
    },
    nexttrack: function() {
      var idx;
      if (this.current_track >= this.playlist.length - 1) {
        idx = 0;
      }
      else if (this.current_track < 0) {
        idx = 0;
      } else {
        idx = this.current_track + 1;
      }
      // Start playback of this.current_track
      // console.log("Starting playback of track: " + idx);
      this.playTrack(idx);
    },
    playTrack: function(index){
      var wWidth = $(window).width() * 0.75; //480;
      var wHeight = $(window).height() * 0.6; // 640;
      if((index < 0)||(index > this.playlist.length - 1)) {
        index = 0;
      }
      this.current_track = index;
      // console.log("Playing track: " + index);
      $('#playerdiv').dialog({
        minWidth: 360,
        minHeight: 480,
        width: wWidth,
        buttons: [
          {
            text: 'OK',
            icon: 'ui-icon-heart',
            click: function() {
              $(this).dialog("close");
            }
          },
          {
            text: '🗖',
            icon: 'ui-icon-arrow-4-diag',
            click: function() {
              $(this).dialog("close");
            }
          }
        ],
        show: { effect: "scale", duration: 1000 },
        hide: { effect: "explode", duration: 1000 },
        closeOnEscape: true,
        closeText: 'X',
        close: function(event, ui) {
          // console.log("Closing dialog");
          window.lyricsindia.playerapp.stop();
        },
        resizeStop: function( event, ui ) {
          // console.log("Dialog resized");
          window.lyricsindia.playerapp.resize(ui.size.width*0.6, ui.size.height*0.8);
        },
      });
        if(this.$refs.thePlayer){
          this.$refs.thePlayer.playVideo();
	        gtag('event', this.mediaid(), {
	          'event_label': $(location).attr('href'),
	          'event_category': 'vidplay'
	        });
          window.lyricsindia.playerapp.resize(wWidth, wHeight);
        }
    },
    onEnded() {
      // console.log("## OnEnded")
      this.nexttrack();
    },
    onPaused() {
      // console.log("## OnPaused")
    },
    onPlayed() {
      // console.log("## OnPlayed")
    },
    onReady() {
      console.log("## OnReady");
    },
    resize(width, height) {
      // console.log("Resizing player");
      this.$refs.thePlayer.setSize(width, height);
    },
    stop() {
      this.$refs.thePlayer.stopVideo();
    },
  },
}
</script>

<style scoped>
</style>
